.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.aboutCom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.descCon {
  text-align: center;
  max-width: 800px;
}

.descCon h3 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.descCon hr {
  margin: 1rem auto;
  border: none;
  height: 3px;
  background-color: #fbbd02;
}

.descPara {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.descPara p {
  margin-bottom: 1rem;
}

.counterCom {
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 3rem 1rem;
  }

  .descCon h3 {
    font-size: 2rem;
  }

  .descPara {
    font-size: 1rem;
  }

  .counterCom {
    padding: 2rem 1rem;
  }

  .countUp {
    font-size: 2.5rem;
  }
}
