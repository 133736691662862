.outer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  padding: 16px 36px;
  margin: auto;
  width: 1024px;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.imgCon {
  height: 200px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.imgCon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  display: block;
}

.details {
  padding: 10px;
  text-align: center;
}

.details p {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

@media (max-width: 1200px) {
  .container {
    border-width: 2px;
  }
}

@media (max-width: 768px) {
  .imgCon {
    height: 150px;
    padding: 12px;
  }

  .details p {
    font-size: 13px;
  }
  .outer {
    width: 768px;
  }
}

@media (max-width: 576px) {
  .outer {
    gap: 10px;
    width: auto;
  }

  .imgCon {
    height: 120px;
    padding: 8px;
  }

  .details p {
    font-size: 12px;
  }
}
