.container {
  display: flex;
  flex-direction: column;
}

.container .goToContact {
  display: flex;
  padding: 42px 32px;
  gap: 32px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
  color: #ffffff;
}

.container .goToContact .descContact h3 {
  font-size: 24px;
  font-weight: 800;
}

.container .goToContact .btnCon {
  display: flex;
  gap: 16px;
}

.container .goToContact .btnCon a {
  text-decoration: none;
  color: inherit;
}

.container .goToContact .btnCon button {
  height: 50px;
  padding: 12px 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 20px;
  background: #1bae61;
  color: #ffffff;
}

.container .goToContact .btnCon button:hover {
  background: #258256;
}

.container .servicesCom {
  height: 70vh;
  background-image: url("/public/images/05.jpg");
  background-position: center;
  background-size: cover;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

.container .servicesCom .offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 600px;
}

.container .servicesCom .offer div h3::after {
  content: " ";
  clear: both;
  width: 5em;
  display: flex;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 4px solid #3498db;
}

.container .servicesCom .iconWithDesc {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  gap: 16px;
  text-align: center;
}

.container .servicesCom .iconWithDesc div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container .servicesCom .iconWithDesc div i {
  font-size: 40px;
  color: #3498db;
  transition: color 0.2s ease-in;
}

.container .servicesCom .iconWithDesc div p {
  font-size: 1.2rem;
}

.container .servicesCom .iconWithDesc div p::after {
  content: " ";
  clear: both;
  width: 5em;
  display: flex;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 4px solid #3498db;
}

.container .servicesCom .iconWithDesc div i:hover {
  color: #e74c3c;
}

.container .servicesCom .offer div:nth-child(2) {
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 1024px) {
  .container .servicesCom .iconWithDesc {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .container .goToContact {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
  .container .goToContact .descContact h3 {
    font-size: 20px;
  }
  .container .goToContact .btnCon {
    flex-direction: column;
    gap: 12px;
  }
  .container .goToContact .btnCon button {
    width: 100%;
  }
  .container .servicesCom {
    height: auto;
    padding: 20px;
  }
  .container .servicesCom .iconWithDesc {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 425px) {
  .container .goToContact {
    padding: 15px;
  }
  .container .goToContact .descContact h3 {
    font-size: 18px;
  }
  .container .goToContact .btnCon {
    gap: 10px;
  }
  .container .servicesCom .iconWithDesc {
    grid-template-columns: 1fr;
  }
}
