.loginContainer {
  max-width: 400px;
  margin: 5% auto;
  padding: 30px;
  border-radius: 15px;
  background: linear-gradient(145deg, #f0f0f0, #ffffff);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.loginContainer h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.formGroup {
  margin-bottom: 25px;
  position: relative;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.formGroup input {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid grey;
  border-radius: 25px;
  background-color: #f7f7f7;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.formGroup input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
}

.loginContainer button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.loginContainer button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

@media (max-width: 480px) {
  .loginContainer {
    max-width: 90%;
    margin: 10% auto;
    padding: 20px;
  }

  .loginContainer h2 {
    font-size: 2rem;
  }

  .formGroup input,
  .loginContainer button {
    padding: 12px;
  }
}

@media (max-width: 320px) {
  .loginContainer h2 {
    font-size: 1.8rem;
  }

  .formGroup input,
  .loginContainer button {
    padding: 10px;
  }
}
