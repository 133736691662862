.container {
  display: flex;
  justify-content: center;
  gap: 32px 64px;
  padding: 32px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 230px;
}

.inner h4 {
  font-size: 1.3rem;
}

.inner p {
  text-align: center;
}

.inner i {
  color: #009e4c;
  font-size: 1.3rem;
  border: 2px solid #009e4c;
  padding: 24px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  font-size: 2.3rem;
  justify-content: center;
}

.inner i:hover {
  background-color: #009e4c;
  color: white;
  transition: all 0.8s ease;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
}
