.navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  align-items: center;
  text-align: center;
  z-index: 1000;
  height: 125px;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  max-width: 1024px;
  margin: 0 auto;
}

.logoLink {
  display: flex;
  align-items: center;
  height: 100px;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 16px;
}

.logoLink img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.logo {
  object-fit: contain;
  height: 60px;
  width: auto;
}

.navMenu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navMenu li {
  margin-left: 2rem;
}

.navMenu a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  position: relative;
}

li a {
  align-items: center;
}
.navMenu a:hover {
  color: #007bff;
}

.navMenu a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.navMenu a:hover::after,
.navMenu a.active::after {
  width: 100%;
}

.navMenu a.active {
  color: #007bff;
  font-weight: 600;
}

.menuToggle {
  display: none;
}

@media (max-width: 768px) {
  .navContainer {
    padding: 0.5rem 1rem;
  }

  .menuToggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }

  .menuIcon {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .menuIcon::before,
  .menuIcon::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease;
  }

  .menuIcon::before {
    top: -8px;
    left: 0px;
  }

  .menuIcon::after {
    bottom: -8px;
    left: 0px;
  }

  .menuToggle.active .menuIcon {
    background-color: transparent;
  }

  .menuToggle.active .menuIcon::before {
    top: 0;
    transform: rotate(45deg);
  }

  .menuToggle.active .menuIcon::after {
    bottom: 0;
    transform: rotate(-45deg);
  }

  .navMenu {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    background-color: #f8f9fa;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  .navMenu.active {
    display: flex;
  }

  .navMenu li {
    margin: 0.5rem 0;
  }

  .navMenu a::after {
    display: none;
  }
}
