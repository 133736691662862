.moveBtn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 114, 255, 0.3);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moveBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #0072ff, #00c6ff);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.moveBtn:hover::before {
  opacity: 1;
}

.moveBtn i {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
}

.moveBtn:hover i {
  transform: translateY(-5px);
}

.moveBtn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease;
}

.moveBtn:hover::after {
  box-shadow: 0 0 0 15px rgba(0, 114, 255, 0.1),
    0 0 0 30px rgba(0, 114, 255, 0.1);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 114, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 114, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 114, 255, 0);
  }
}

.moveBtn {
  animation: pulse 2s infinite;
}

@media (max-width: 768px) {
  .moveBtn {
    width: 50px;
    height: 50px;
    font-size: 20px;
    bottom: 20px;
    right: 20px;
  }
}
