.footer {
  background: linear-gradient(135deg, #1a1a1a, #2c2c2c);
  color: #fff;
  padding: 60px 0 30px;
  font-family: "Poppins", Arial, sans-serif;
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(0, 212, 255, 0.1) 0%,
    rgba(0, 212, 255, 0) 70%
  );
  animation: pulse 15s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.footerSection {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.footerSection:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.2);
}

.footerSection h3 {
  color: transparent;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  -webkit-background-clip: text; 
  background-clip: text; 
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
}

.footerSection h3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: #00d4ff;
  border-radius: 3px;
}

.contactInfo p {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.contactInfo i {
  margin-right: 15px;
  color: #00d4ff;
  width: 20px;
  font-size: 18px;
}
.emailLink a {
  color: #fff;
  text-decoration: none;
}

.footerSection ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerSection ul li {
  margin-bottom: 12px;
}

.footerSection ul li a,
.socialIcons a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  position: relative;
}

.footerSection ul li a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #00d4ff;
  transition: width 0.3s ease;
}

.footerSection ul li a:hover::before {
  width: 100%;
}

.socialIcons {
  display: flex;
  gap: 15px;
}

.socialIcons a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 2px solid #00d4ff;
  border-radius: 50%;
  font-size: 18px;
  transition: all 0.3s ease;
}

.socialIcons a:hover {
  background: #00d4ff;
  color: #1a1a1a;
  transform: rotate(360deg);
}

.footerBottom {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .footerSection {
    width: 100%;
    max-width: 400px;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 40px 0 20px;
  }

  .footerSection h3 {
    font-size: 20px;
  }

  .contactInfo p,
  .footerSection ul li a,
  .footerBottom {
    font-size: 14px;
  }

  .socialIcons a {
    font-size: 16px;
    height: 35px;
    width: 35px;
  }
}
