.adminPanel {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.adminPanel h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form input:focus {
  outline: none;
  border-color: #007bff;
}

.form button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.form button:hover {
  background-color: #0056b3;
}

.productList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

.productItem {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 0.3s ease;
}

.productItem:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 15px;
}
.productItem .imgCon {
  height: 200px;
  width: 250px;
}
.imgCon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.productItem h3 {
  margin: 0 0 15px 0;
  text-align: center;
  color: #333;
}

.btnCon {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.btnCon button {
  margin: 5px;
  padding: 8px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.btnCon button:hover {
  background-color: #218838;
}

.btnCon  .deleteBtn {
  background-color: #dc3545;
}

.btnCon .deleteBtn:hover {
  background-color: #c82333;
}

.btnCon2 {
  display: flex;
  gap: 10px;
}

.loader {
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
  color: #007bff;
}

.error {
  color: #dc3545;
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  padding: 15px;
  background-color: #f8d7da;
  border-radius: 6px;
}

.productContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.productCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  transition: transform 0.3s ease;
}

.productCard:hover {
  transform: translateY(-5px);
}

.productImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.productName {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px;
  color: #333;
}

.productDetails {
  padding: 0 10px;
}

.productDetails p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #666;
}

.productActions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.productActions button {
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.productActions button:hover {
  background-color: #45a049;
}

.productActions button:nth-child(2) {
  background-color: #f44336;
}

.productActions button:nth-child(2):hover {
  background-color: #d32f2f;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .adminPanel {
    padding: 15px;
  }

  .form {
    max-width: 100%;
  }

  .productList {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .productItem {
    padding: 15px;
  }

  .productImage {
    height: 150px;
  }

  .productItem button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .adminPanel h1 {
    font-size: 24px;
  }

  .form input,
  .form button {
    font-size: 14px;
    padding: 10px;
  }

  .productList {
    grid-template-columns: 1fr;
  }

  .productItem h3 {
    font-size: 18px;
  }
}
