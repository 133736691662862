.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.container .imgCon {
  height: 200px;
  border-radius: 50%;
  width: 200px;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.container .imgCon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  animation: spin 2s linear infinite;
}

.container .imgCon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
