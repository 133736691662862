.card {
  /* width: 300px; */
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  height: 24px;
  background-color: #e0e0e0;
  width: 60%;
  border-radius: 4px;
}

.text {
  height: 16px;
  background-color: #e0e0e0;
  width: 100%;
  border-radius: 4px;
}

.image,
.title,
.text {
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.error {
  color: red;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}
