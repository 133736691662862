.mainContainer {
  height: 70vh;
  width: 100%;
  position: relative;
}

.mainContainer img {
  height: 100%;
  width: 100%;
  padding: 100px;
  object-fit: contain;
  border-radius: 12px;
}

@media screen and (max-width: 450px) {
  .mainContainer {
    height: 50vh;
    width: 100%;
  }
}

.arrow {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  outline: none;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-50%) scale(1.1);
}

.arrow:active {
  transform: translateY(-50%) scale(0.95);
}

.arrowPrev {
  left: 20px;
}

.arrowNext {
  right: 20px;
}

.arrow i {
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.arrow:hover i {
  color: #000;
}

@media (max-width: 768px) {
  .arrow {
    width: 40px;
    height: 40px;
  }

  .arrow i {
    font-size: 20px;
  }

  .arrowPrev {
    left: 10px;
  }

  .arrowNext {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .arrow {
    width: 35px;
    height: 35px;
  }

  .arrow i {
    font-size: 18px;
  }
}
