/* detailsPage.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 24px;
  max-width: 800px;
  margin: 0 auto;
}

.imgCon {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imgCon img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
}

.desc p {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
}

.btnCon {
  display: flex;
  gap: 16px;
}

.btnCon button:nth-child(1) {
  background-color: #009147;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.btnCon button:nth-child(2) {
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.btnCon button:nth-child(1):hover {
  background-color: #0b7e43;
}

.btnCon button:nth-child(2):hover {
  background-color: rgb(235, 22, 22);
}

.desc button:active {
  transform: scale(0.98);
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .imgCon {
    max-width: 100%;
    height: auto;
  }

  .desc {
    gap: 8px;
  }

  .desc p {
    font-size: 1rem;
  }

  .desc button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
