/* .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.mainTitle {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.introduction {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.visionPoints {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.visionPoint {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.visionPoint:hover {
  transform: translateY(-5px);
}

.visionTitle {
  font-size: 1.3rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.visionDescription {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.conclusion {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #2c3e50;
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
  }

  .mainTitle {
    font-size: 2rem;
  }

  .introduction {
    font-size: 1rem;
  }

  .visionPoints {
    grid-template-columns: 1fr;
  }
} */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #f9f9f9; /* Add background color for better contrast */
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center; /* Center text for better readability */
}

.mainTitle {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1rem;
}

.introduction {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

.visionPoints {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.visionPoint {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #ddd; /* Add border for better separation */
}

.visionPoint:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.visionTitle {
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 600; /* Slightly bolder titles */
}

.visionDescription {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.conclusion {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #2c3e50;
  margin-top: 2rem;
  padding: 1rem;
  background-color: #eaf0f6; /* Subtle background color */
  border-radius: 8px; /* Rounded corners */
}

@media screen and (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
  }

  .mainTitle {
    font-size: 2rem;
  }

  .introduction {
    font-size: 1rem;
  }

  .visionPoints {
    grid-template-columns: 1fr;
  }

  .visionPoint {
    padding: 1.5rem;
  }

  .conclusion {
    font-size: 1rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .mainTitle {
    font-size: 1.8rem;
  }

  .introduction {
    font-size: 0.9rem;
  }

  .visionTitle {
    font-size: 1.2rem;
  }

  .visionDescription {
    font-size: 0.9rem;
  }
}
