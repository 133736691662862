.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  flex-direction: column;
  gap: 16px;
}

.container p:nth-child(1){
    font-size: 24px;
    
}

.container p:nth-child(2) {
  font-size: 2rem;
  font-weight: 650;
}

.container button {
  background-color: #00d4ff;
  padding: 8px;
}
